import API from "decentraland-gatsby/dist/utils/api/API"
import env from "decentraland-gatsby/dist/utils/env"

import { BlogPostAttributes } from "./formats"

const GATSBY_BASE_URL = env("BASE_URL", "/")

export default {
  home: () => API.url(GATSBY_BASE_URL, "/"),
  blogs: () => API.url(GATSBY_BASE_URL, "/blog/"),
  category: (category: string) => API.url(GATSBY_BASE_URL, `/blog/${category}`),
  blog: (category: string, blog: string) =>
    API.url(GATSBY_BASE_URL, `/blog/${category}/${blog}`),
  author: (author: string) =>
    API.url(GATSBY_BASE_URL, `/blog/author/${author}`),
  search: (query: string) =>
    API.url(GATSBY_BASE_URL, `/blog/search/?q=${query}`),
  twitter: (post: BlogPostAttributes) =>
    API.url(
      "https://twitter.com",
      `/share?text=${post.title}&url=${API.url(
        GATSBY_BASE_URL,
        `/blog/${post.categoryId}/${post.id}`
      )}`
    ),
  facebook: (post: BlogPostAttributes) =>
    API.url(
      "https://www.facebook.com",
      `/sharer/sharer.php?u=${API.url(
        GATSBY_BASE_URL,
        `/blog/${post.categoryId}/${post.id}`
      )}`
    ),
}

export function redirectToAuthDapp(customRedirect?: string) {
  window.location.replace(
    `${env("AUTH_URL")}/login?redirectTo=${encodeURIComponent(
      customRedirect ? customRedirect : window.location.href
    )}`
  )
}
